<template>
  <div class="container-fluid mt--6 api-page">
    <div class="card mb-3">
      <div class="card-header">Roles Setting</div>
      <div class="card-body" v-loading="loading" >
        <el-table 
          :data="tableData" 
          border 
          size="mini" s
          tyle="width: 100%">
          <el-table-column prop="label" label="Resource">
            <template slot-scope="scope">
              <span
                ><b>{{ scope.row.label }}</b></span
              >
            </template>
          </el-table-column>
          <el-table-column prop="admin" label="Admin" width="90" align="center">
            <template slot-scope="scope">
              <el-checkbox
                v-model="tableData[scope.$index].admin"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="user" label="User" width="90" align="center">
            <template slot-scope="scope">
              <el-checkbox
                v-model="tableData[scope.$index].user"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="paymaker"
            label="Paymaker"
            width="90"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-model="tableData[scope.$index].paymaker"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="accounting"
            label="Accounting"
            width="90"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-model="tableData[scope.$index].accounting"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="treasury"
            label="Treasury"
            width="90"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-model="tableData[scope.$index].treasury"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="individual"
            label="Individual"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-model="tableData[scope.$index].individual"
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-footer">
        <el-button type="primary" @click="submitData()" v-loading="submiting" :disabled="submiting">
          Save setting
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils";
import ManageApi from "@/api/manage";
export default {
  data() {
    return {
      submiting: false,
      loading: false,
      roles: [
        { id: "admin", name: "Admin" },
        { id: "user", name: "User" },
        { id: "paymaker", name: "Paymaker" },
        { id: "accounting", name: "Accounting" },
        { id: "treasury", name: "Treasury" },
        { id: "individual", name: "Individual" },
      ],
      resource: [
        { id: "statement", name: "Statement" },
        { id: "card", name: "Card" },
        { id: "introducer", name: "Introducer" },
        { id: "payment", name: "Payment" },
        { id: "swiftpending", name: "Swift pending" },
        { id: "payee", name: "Payee" },
        { id: "autofx", name: "Auto FX" },
        { id: "manualfx", name: "Manual FX" },
        { id: "instant", name: "Instant" },
        { id: "standingorder", name: "Standing order" },
        { id: "receive", name: "Receive" },
        { id: "viewprofile", name: "View profile" },
        { id: "devicemanage", name: "Device manage" },
        { id: "usermanage", name: "User manage" },
        { id: "changepassword", name: "Change password" },
        { id: "updatepin", name: "Update PIN" },
        { id: "term", name: "Term" },
        { id: "pricetariff", name: "Price Tariff" },
        { id: "bankaccount", name: "Bank account" },
        { id: "balance", name: "Balance" },
      ],

      data: [],
      tableData: [],
    };
  },
  methods: {
    mapDefaultData() {
      this.loading = true;
      this.resource.forEach((item) => {
        this.tableData.push({
          resource: item.id,
          label: item.name,
          admin: false,
          user: false,
          paymaker: false,
          accounting: false,
          treasury: false,
          individual: false,
        });
      });
    },
    mapTableData() {
      this.tableData.forEach((item) => {
        let resource = Utils.findObjectArray(
          this.data, // array
          item.resource, // value
          "resource" // key
        );
        Object.keys(item).forEach((key) => {
          if (!["resource", "label"].includes(key)) {
            resource.forEach((res) => {
              if (res.role == key) {
                item[key] = true;
              }
            });
          }
        });
      });
    },
    getRoleSetting() {
      
      ManageApi.getRoleSetting().then(({ result, data }) => {
        this.loading = false;
        if(result){
          this.data = data;
          this.mapTableData();
        }
      });
    },
    mapSubmitData() {
      var data = [];
      this.tableData.forEach((item) => {
        if (item.admin) {
          data.push({
            role: "admin",
            resource: item.resource,
            action: "update:any",
          });
        }
        if (item.user) {
          data.push({
            role: "user",
            resource: item.resource,
            action: "update:any",
          });
        }
        if (item.paymaker) {
          data.push({
            role: "paymaker",
            resource: item.resource,
            action: "update:any",
          });
        }
        if (item.accounting) {
          data.push({
            role: "accounting",
            resource: item.resource,
            action: "update:any",
          });
        }
        if (item.treasury) {
          data.push({
            role: "treasury",
            resource: item.resource,
            action: "update:any",
          });
        }
        if (item.individual) {
          data.push({
            role: "individual",
            resource: item.resource,
            action: "update:any",
          });
        }
      });

      return data;
    },

    submitData() {
      this.submiting = true;
      let data = this.mapSubmitData();
      ManageApi.saveRoleSetting({roles: data}).then(({ result, message }) => {
        this.submiting = false;
        if(result){
              this.$swal(`Message`, `Saved role setting`, 'success')
            }else{
              this.$swal('Message', message, 'error')
            }
      });
    },
  },
  mounted() {
    this.mapDefaultData();
    this.getRoleSetting();
  },
};
</script>

<style>
</style>